import Loadable from 'react-loadable';
import LoadingContent from './components/LoadingContent';

import { getHomeData } from './context/actions';

const routes = [
  {
    path: '/',
    exact: true,
    sectionName: 'Моите Файлове',
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/home" */ './pages/home'),
      loading: LoadingContent,
    }),
    fetchInitialData: () => getHomeData(),
  },
  {
    path: '/login',
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/login" */ './pages/login'),
      loading: LoadingContent,
    }),
    fetchInitialData: () => Promise.resolve({ home: 'OK' }),
  },
  {
    path: '/register',
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/login" */ './pages/register'),
      loading: LoadingContent,
    }),
  },
  {
    path: '/forgot',
    exact: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/forgot" */ './pages/forgot'),
      loading: LoadingContent,
    }),
  },
  {
    path: '/upload',
    sectionName: 'Прикачи файл',
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/upload" */ './pages/upload'),
      loading: LoadingContent,
    }),
    fetchInitialData: () => Promise.resolve({ home: 'OK' }),
  },
  {
    path: '/about',
    exact: true,
    secure: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/about" */ './pages/AboutPage'),
      loading: LoadingContent,
    }),
    fetchInitialData: () => Promise.resolve('about'),
  },
  {
    path: '/testpage',
    exact: true,
    secure: true,
    component: Loadable({
      loader: () => import(/* webpackChunkName: "pages/testpage" */ './pages/TestPage'),
      loading: LoadingContent,
    }),
    fetchInitialData: () => Promise.resolve('testpage'),
  },
]

export default routes;
