const DEV = process.env.NODE_ENV === 'development'

const config = {
  sashido: {
    applicationId: 'QQ8MWYoEUJHBZiUu9zUn61coNQVJPBIFHg5OrAF5',
    javascriptKey: 'BVjixNMqk87W6Hbw59fjkWbCrGAPFBJHNOmQgdUI',
  },
};

if (DEV) {
  config.sashido.serverURL = 'http://localhost:1338/1/';
} else {
  config.sashido.serverURL = 'https://pg-app-42auwdudtmza8j0esideuppdy5pl2h.scalabl.cloud/1/';
}


export default config;
