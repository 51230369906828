
import Parse from '../../lib/parse';
import { SetCookie, DeleteCookie } from '../../lib/cookies';
import { getFileExtOnly, getRealFileNameFromURL } from '../../lib/helpers';
import config from '../../config'


const { applicationId, javascriptKey, serverURL } = config.sashido
Parse.initialize(applicationId, javascriptKey)
Parse.serverURL = serverURL;

const collectionUser = Parse.Object.extend(Parse.User);
const collectionFiles = Parse.Object.extend('Files');

export const getCurrentUser = () => Parse.User.current();

export const logIn = async ({ username, password }) => {
  try {
    const currentUser = await Parse.User.logIn(username, password);
    const token = currentUser.getSessionToken();

    window.localStorage.removeItem(`Parse/${applicationId}/currentUser`);
    window.localStorage.setItem(`Parse/${applicationId}/currentUser`, JSON.stringify(currentUser));
    SetCookie('docs-server', token, 7, null, null, true);

    const isAdmin = !!(currentUser.get('ACL').permissionsById['role:docs-admin']);

    return {
      currentUser,
      isAdmin,
    }
  } catch (e) {
    return {
      error: {
        message: e.message,
      }
    }
  }
};

export const logOut = async () => {
  Parse.User.logOut();
  window.localStorage.removeItem(`Parse/${applicationId}/currentUser`);
  DeleteCookie('docs-server');
};

export const forgot = async ({ username }) => {
  try {
    const r = await Parse.User.requestPasswordReset(username);
    return r;
  } catch (e) {
    return {
      error: {
        message: e.message,
      }
    }
  }
}

export const creteUserRole = async (userId) => {
  const ACL = new Parse.ACL();

  const user = new collectionUser();
  user.id = userId;

  // Create ACL: user should have access to the role
  ACL.setReadAccess(userId, true);
  ACL.setWriteAccess(userId, true);

  const role = new Parse.Role(`user-${userId}`, ACL);
  role.getUsers().add(user);

  return role.save();
}

export const register = async ({ username, password }) => {
  try {
    const user = new Parse.User();

    user.set('email', username);
    user.set('username', username);
    user.set('password', password);

    let currentUser;
    try {
      currentUser = await user.signUp();
    } catch (e) {
      return {
        error: {
          message: e.message,
        }
      }
    }

    try {
      await creteUserRole(currentUser.id);
    } catch (e) {
      return {
        error: {
          message: e.message,
        }
      }
    }

    return logIn({ username, password });
  } catch (e) {
    return {
      error: {
        message: e.message,
      }
    }
  }
};



const prepareACLs = (selectedUsers) => {
  console.log('selectedUsers', selectedUsers)
  const ACL = new Parse.ACL();

  [selectedUsers].forEach((user) => {
    ACL.setReadAccess(`role:user-${user.value}`, true);
    ACL.setWriteAccess(`role:user-${user.value}`, true);
  });

  ACL.setReadAccess('role:docs-admin', true);
  ACL.setWriteAccess('role:docs-admin', true);

  return ACL;
}

export const saveFiles = async ({ files, selectedUsers }) => {
  const prepareFiles = await Promise.all([].map.call(files, (file, i) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async () => {
        const base64 = reader.result;
        const fileForUpload = new Parse.File(`${file.fname}.${file.ftype}`, { base64 });
        const obj = new collectionFiles();

        obj.setACL(prepareACLs(selectedUsers));

        obj.set('file', fileForUpload);
        obj.set('desc', file.fdesc);
        obj.set('title', file.ftitle);
        obj.set('lastModified', file.lastModified);

        resolve(obj.save());
      };
      reader.readAsDataURL(file);
    });
  }));

  const result = await prepareFiles;
  return result;
}

export const getHomeData = async () => {
  const query = new Parse.Query(collectionFiles);
  query.include(['firstPreviewFrom']);
  query.descending('createdAt');
  const result = await query.find();


  return {
    data: result.map((item) => {
      const { _url: fileURL } = item.get('file');
      const name = getRealFileNameFromURL(fileURL);

      return {
        id: item.id,
        ...item.attributes,
        name,
        type: getFileExtOnly(name),
        file: fileURL,
        firstPreviewDate: item.get('firstPreviewDate'),
        firstPreviewFrom: item.get('firstPreviewFrom'),
      }
    })
  }
};

export const setFirstPreview = async ({ objectId }) => {
  console.log('objectId', objectId)
  const file = new collectionFiles();
  file.id = objectId;

  file.set('firstPreviewDate', new Date());
  file.set('firstPreviewFrom', Parse.User.current());

  try {
    await file.save();
  } catch(e) {
    console.log('EEEEE', e.message);
  }
};
