export const getCookieVal = (offset) => {
  let endstr = document.cookie.indexOf(";", offset);
  if (endstr === -1) { endstr = document.cookie.length; }
  return unescape(document.cookie.substring(offset, endstr));
}

export const GetCookie = (name) => {
  const arg = `${name  }=`;
  const alen = arg.length;
  const clen = document.cookie.length;
  let i = 0;
  while (i < clen) {
    const j = i + alen;
    if (document.cookie.substring(i, j) == arg) {
      return getCookieVal(j);
    }
    i = document.cookie.indexOf(" ", i) + 1;
    if (i == 0) break;
  }
  return null;
}

export const DeleteCookie = (name, path = '/', domain) => {
  if (GetCookie(name)) {
    document.cookie = `${name  }=${
      (path) ? `; path=${  path}` : ""
      }${(domain) ? `; domain=${  domain}` : ""
      }; expires=Thu, 01-Jan-70 00:00:01 GMT`;
  }
}

export const SetCookie = (name, value, expires, path = '/', domain, httpOnly) => {
  const d = new Date;
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * expires);

  document.cookie = `${name  }=${  escape(value)
    }${(expires) ? `; expires=${  d.toGMTString()}` : ""
    }${(path) ? `; path=${  path}` : ""
    }${(domain) ? `; domain=${  domain}` : ""
    }${(httpOnly) ? "; httpOnly:true" : "httpOnly:false"}`;
}
