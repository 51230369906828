export const getBase64ImageFromUrl = async (imageUrl) => {
  const res = await fetch(imageUrl);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    }, false);

    reader.onerror = (e) => {
      return reject(e);
    };
    reader.readAsDataURL(blob);
  });
};

export const getFileNameOnly = (fileName) => fileName.replace(/\.[^\.]+$/, '');
export const getFileExtOnly = (fileName) => fileName.substr(fileName.lastIndexOf('.') + 1);
export const getFullFileNameFromURL = (url) => url.substring(url.lastIndexOf('/') + 1);

export const prepareFileName = (fileName) => {
  const name = getFileNameOnly(fileName);
  const newFileName = name.replace(/[^A-Z0-9]+/ig, "_").replace(/\s+/g, "_").replace(/\_$/, '');

  return newFileName;
}


export const getRealFileNameFromURL = (url) => {
  const fileName = getFullFileNameFromURL(url);
  return fileName.substring(fileName.indexOf('_') + 1)
}

export const saveFileOnUserDevice = async (url) => {
  const realFileName = getRealFileNameFromURL(url);

  const r = await fetch(url)
  const file = await r.blob();


  // content: blob, name: string
  if (navigator.msSaveBlob) { // For ie and Edge
    return navigator.msSaveBlob(file, realFileName);
  }

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(file);
  link.download = realFileName;
  document.body.appendChild(link);
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  link.remove();
  window.URL.revokeObjectURL(link.href);

  return null;
}
