import React from 'react';
import { Link } from 'react-router-dom';
import { createConsumer } from '../../context';
import Avatar from '../Avatar';
import Icon from '../Icon';
import styles from './Header.scss';

const Header = (props) => {
  const {
    currentUser,
    isAdmin,
    logOut,
    sectionName,
    path,
  } = props;

  if (!currentUser) {
    return null;
  }

  const src = (currentUser.get('avatar') || {})._url || null;
  const name = currentUser.get('username') || '';
  const fullName = currentUser.get('fullName') || '';

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.logo}>
          <Link to="/admin">
            <img src="/images/logo.png" height="30" alt="" />
          </Link>

          {sectionName}
        </div>

        <div className={styles.toolbar}>
          {
            name && src &&
              <Avatar size="48" name={name} src={src} className={styles.avatar} />
          }

          {
            isAdmin && !(path.startsWith('/upload')) &&
              <Link to="/upload" className={styles.link}>
                <span className={styles.add}>
                  <Icon name="icon-plus" width={8} height={8} fill="#fff" />
                </span>
                Добави Файлове
              </Link>
          }

          <Link
            to="/"
            value='Профил'
            className={styles.link}>{fullName}</Link>

          <Link
            to="/login"
            value='LogOut'
            className={styles.link}
            onClick={logOut}>Изход</Link>
        </div>
      </div>
    </div>
  );
}


const mapContextToProps = ({ state, action }) => ({
  currentUser: state.currentUser,
  isAdmin: state.isAdmin,
  logOut: action.logOut,
});

export default createConsumer(mapContextToProps)(Header);
