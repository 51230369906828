import {
  logIn,
  logOut,
  register,
  forgot,
  saveFiles,
  getHomeData,
  setFirstPreview,
} from './actions'

const createActions = store => ({
  action: {
    setCurrentUser: currentUser => store.setState({ currentUser }),
    getCurrentUser: () => store.state.currentUser,

    logIn: async ({ username, password }) => {
      const { currentUser, isAdmin, error } = await logIn({ username, password });

      if (currentUser) {
        store.setState({
          currentUser,
          isAdmin,
        });

        return null;
      }

      return error;
    },

    logOut: () => {
      logOut();

      store.setState({
        currentUser: null,
      });

      window.location.href = '/login'
    },

    register: async ({ username, password }) => {
      const { currentUser, isAdmin, error } = await register({ username, password });

      if (currentUser) {
        store.setState({
          currentUser,
          isAdmin,
        });

        return null;
      }

      return error;
    },

    forgotPassword: async ({ username }) => {
      logOut();
      const { error } = await forgot({ username });
      return error;
    },

    saveFiles: async ({ files, selectedUsers }) => {
      saveFiles({ files, selectedUsers });
    },

    getHomeData: async () => {
      const obj = await getHomeData();
      store.setState({
        ...obj,
      });
    },

    setFirstPreview: ({ objectId }) => {
      if (!store.state.isAdmin) {
        setFirstPreview({ objectId })
      }
    },
  },
  state: {
    currentUser: store.state.currentUser || null,
    isAdmin: store.state.isAdmin || null,
  },
});

export default createActions;
