import Icon from 'components/Icon';
import React from 'react';
import styles from './Button.scss';

const noop = () => { };

const Button = (props) => {
  const hasOnClick = props.onClick && !props.disabled;
  const classes = [styles.button];

  let icon = null;
  // if a button is disabled, that overrides any color selection
  if (props.disabled) {
    classes.push(styles.disabled);
    if (props.color === 'white') {
      // This has a special disabled case
      classes.push(styles.white);
    }
  } else {
    if (props.primary) {
      classes.push(styles.primary);
    }
    if (props.color) {
      classes.push(styles[props.color]);
    }

    if (props.icon) {
      icon = <Icon width={props.iconSize ? props.iconSize : 14} height={props.iconSize ? props.iconSize : 14} colored={!!props.colored} name={props.icon} />;
    }

    if (props.progress) {
      classes.push(styles.progress);
    }
  }

  const clickHandler = hasOnClick ? props.onClick : noop;
  let styleOverride = null;
  if (props.width) {
    styleOverride = { width: props.width, minWidth: props.width, ...props.additionalStyles };
  }

  if (props.theme === 'dark') {
    classes.push(styles.dark);
  }

  if (props.size === 'big') {
    classes.push(styles.big);
  }

  if (props.size === 'small') {
    classes.push(styles.small);
  }

  if (props.block) {
    classes.push(styles.block);
  }

  return (
    <span
      style={styleOverride}
      className={classes.join(' ')}
      onClick={clickHandler}
      onFocus={(e) => { if (props.disabled) e.target.blur(); }} >
      {icon}
      {
        props.value
          ?
          <span>{props.value}</span>
          :
          null
      }
    </span>
  );
}

export default Button;
