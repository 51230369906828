/* eslint-disable */
let Parse;
if (__isBrowser__) {
  Parse = require('parse');
} else {
  Parse = require('parse/node');
}
/* eslint-enable */

export default Parse;
