/* eslint react/jsx-filename-extension: 'off' */

import 'core-js';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Loadable from 'react-loadable';
import App from './App';

const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader-hide');
const hideLoader = () => loader.classList.add('loader-hide');

Loadable.preloadReady().then(() => {
  const jsx = (
    <Router>
      <App hideLoader={hideLoader} showLoader={showLoader} />
    </Router>
  );

  const renderMethod = module.hot ? render : hydrate

  renderMethod(jsx, document.getElementById('app'));
});


if (module.hot) {
  module.hot.accept()
}
