import React, { Component } from 'react';
import classnames from 'classnames';
import initials from 'initials';
import contrast from 'contrast';

import styles from './Avatar.scss';

const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
];

const sumChars = (str) => {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
}

class UserAvatar extends Component {
  render() {
    let {
      borderRadius = '100%',
      src,
      name,
      color,
      colors = defaultColors,
      size,
      style,
      onClick,
      className
    } = this.props;

    if (!name) throw new Error('UserAvatar requires a name');

    const innerStyle = {
      fontSize: `${size / 3}px`,
      borderRadius
    };

    if (size) {
      innerStyle.width = innerStyle.maxWidth = `${size}px`;
      innerStyle.height = innerStyle.maxHeight = `${size}px`;
    }

    let inner
    let classes = [className, styles.avatar];
    if (src) {
      const imageStyle = {
        display: 'block',
        borderRadius,
        background: `url(${src}) no-repeat 50% / cover`,
      };

      inner = <div className={styles.img} style={imageStyle} />
    } else {
      let background;
      if (color) {
        background = color;
      } else {
        // pick a deterministic color from the list
        let i = sumChars(name) % colors.length;
        background = colors[i];
      }

      innerStyle.backgroundColor = background;

      inner = initials(name);
    }

    if (innerStyle.backgroundColor) {
      classes.push(styles[contrast(innerStyle.backgroundColor)]);
    }

    return (
      <div aria-label={name} className={classnames(classes)} style={style}>
        <div className={styles.inner} style={innerStyle}>
          {inner}
        </div>
      </div>
    )
  }
}

export default UserAvatar;
