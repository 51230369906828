import React, { createContext } from 'react';
import createActions from './createActionsAndState';

const { Provider, Consumer } = createContext();

const createConsumer = contextToProps => Component => props => (
  <Consumer>
    {context => <Component {...(contextToProps(context, props) || {})} {...props} />}
  </Consumer>
);

const createStore = (store) => {
  const actionsAndState = createActions(store);
  return { ...actionsAndState };
};

const withData = Component => props => {
  let initialData = {};
  if (__isBrowser__) {
    initialData = window.__INITIAL_DATA__;
  } else {
    initialData = (props.staticContext || {}).initialData
  }

  return (
    <Consumer>
      {context => <Component {...props} initialData={initialData} />}
    </Consumer>
  );
};

export {
  Provider,
  createConsumer,
  createStore,
  withData
};
