import React, { Component, Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import routes from './routes';
import Parse from './lib/parse';
import config from './config'

import { Provider, createStore } from './context';


import faviconPath from './favicon.ico';
import Header from './components/Header';

import './styles/_loader.scss';
import './styles/global.scss';

const { applicationId, javascriptKey, serverURL } = config.sashido;
Parse.initialize(applicationId, javascriptKey);
Parse.serverURL = serverURL;


class Entry extends Component {
  constructor(props) {
    super(props);

    const { initialData } = props;

    this.state = {
      initialData,
    };
  }

  componentDidMount() {
    const { hideLoader } = this.props;

    /* eslint-disable */
    const initialData = window.__INITIAL_DATA__;

    if (__isBrowser__ === true && initialData) {
      delete window.__INITIAL_DATA__;

      this.state.initialData = initialData;
    }

    const currentUser = Parse.User.current();


    let isAdmin = false;
    if (currentUser) {
      const userACL = currentUser.get('ACL');
      if (userACL) {
        isAdmin = !!(currentUser.get('ACL').permissionsById['role:docs-admin'])
      }
    }

    this.setState({
      currentUser,
      isAdmin,
    });

    setTimeout(() => {
      hideLoader();
    }, 1000);
  }

  render() {
    return(
      <Provider value={createStore(this)}>
        <Helmet defaultTitle="docs.techcontrol.bg" titleTemplate="docs.techcontrol.bg">
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <link rel="shortcut icon" href={faviconPath} type="image/x-icon" />
        </Helmet>
        <App initialData={this.state.initialData} />
      </Provider>
    );
  }
}

// eslint-disable-next-line react/no-multi-comp
class App extends Component {
  render() {
    const { initialData } = this.props;

    return (
      <Switch>
        {
          routes.map(({ path, exact, sectionName, component: ComponentHOC, ...rest }) => (
            <Route key={path} path={path} exact={exact} render={(props) => {
              // console.log('isSecure', !!rest.secure)
              const authenticated = true;
              return authenticated
                ?
                <Fragment>
                  {
                    <Header sectionName={sectionName} path={path} />
                  }
                  <div className={`container ${path.startsWith('/upload') ? 'footer' : ''}`}>
                    <div className="inner">
                      <ComponentHOC {...props} {...rest} initialData={initialData} />
                    </div>
                  </div>
                </Fragment>
                : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }} {...rest} />
          ))
        }
        {/* <Route render={(props) => <NoMatch {...props} />} /> */}
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    );
  };
};

export default Entry;
